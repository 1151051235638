<template>
  <div class="wrapper">
    <UnAuthHeader />
    <main class="page">
			<div class="container-615">
				<section class="entity">
					<h1 class="entity__title title">Ввод пароля</h1>
					<form class="entity__form form">
						<article class="entity__block">
              <Password
                title="Введите пароль"
                @value-input="saveValue"
                field="new_password"
                :error="errors.new_password"
              />
              <Password
                title="Введите пароль еще раз"
                @value-input="saveValue"
                field="new_password_confirm"
                :error="errors.new_password_confirm"
              />
						</article>
						<article>
							<input type="submit" class="form__submit" value="Сохранить">
						</article>
					</form>
					<div class="entity__already-login">
						<a href="/auth" class="entity__already-login-link">Вернуться к авторизации</a>
					</div>
				</section>
			</div>
		</main>
    <Footer />
  </div>
</template>

<script>
import { API } from "../../helpers/api";
import { Validation } from "../../helpers/validation";

import UnAuthHeader from "../../components/headers/UnAuth.vue";
import Password from "../../components/utils/Password.vue";
import Footer from "../../components/Footer.vue";

export default {
  name: "RecoveryProcess",
  props: ['token'],
  components: {
    UnAuthHeader,
    Password,
    Footer,
  },
  data() {
    return {
      values: {
        new_password: "",
        new_password_confirm: "",
      },
      errors: {
        new_password: "",
        new_password_confirm: "",
      },
    };
  },
  created() {
    if(!this.token) {
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    saveValue(data) {
      this.values[data.field] = data.value;
      for (const key in this.errors) {
        this.errors[key] = "";
      }
    },
    async sendForm() {
      this.errors.new_password = Validation.checkPassword(this.values.new_password);
      this.errors.new_password_confirm =
        this.values.new_password === this.values.new_password_confirm
          ? ""
          : "Пароли не совпадают";

      if(Object.values(this.errors).some((result) => result.length > 0)) return;

      try {
        const response = await API.send("changePassword", this.values, "POST");

        if (response.status !== 0) {
          this.errors.new_password = response.user_message;
          return;
        }
      } catch (e) {
        this.errors.new_password = "Неизвестная ошибка. Попробуйте позже.";
      }
    },
  },
};
</script>
